.data-table {
  .editable-cell {
    .edit-icon {
      visibility: hidden;
    }

    &:hover {
      .edit-icon {
        visibility: visible;
      }
    }
  }
}

.field-managment {
  .field-header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    background: #f4f6f7;
  }
}

.field-managment-table {
  .table-head {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;

    tr {
      background-color: #f4f6f7;


      th {
        background-color: #f4f6f7;
        font-size: 18px;
        font-weight: 700;
      }
    }
  }

  tr {
    background: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    margin-bottom: 10px;
    width: 100%;
    display: inline-table;
    border-radius: 4px;

    td {
      border: none;
    }
  }
}

.opportunity-files {
  .dropzone {
    align-items: center;
    border: 1px solid #D9D9D9;
    // background: #FFFFFF;
    display: flex;
    justify-content: center;
    min-height: 136.55px;
  }
}