.notification-container {
    top: 90px;
  }
  .high-priority-notifications .notistack-SnackbarContainer{
    position: unset;
    max-width: 100%;
    flex-direction: column-reverse;
  }
  .medium-priority-notifications .notistack-SnackbarContainer{
    position: unset;
    max-width: 100%;
    flex-direction: column-reverse;
  }
  .low-priority-notifications .notistack-SnackbarContainer{
    position: unset;
    max-width: 100%;
    flex-direction: column-reverse;
  }
  .normal-high-toast-notifications .notistack-SnackbarContainer{
    position: unset;
    max-width: 100%;
    flex-direction: column-reverse;
  }
  .normal-medium-toast-notifications .notistack-SnackbarContainer{
    position: unset;
    max-width: 100%;
    flex-direction: column-reverse;
  }
  .normal-low-toast-notifications .notistack-SnackbarContainer{
    position: unset;
    max-width: 100%;
    flex-direction: column-reverse;
  }