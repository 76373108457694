.eijent-pagination {
  padding: 20px 30px;

  select {
    width: 70px;
    height: 24px;
    background: #edecf5;
    border-radius: 2px;
    border: 0;
    margin: 0 7px;
    padding: 2px 8px;
  }

  ul.pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    li {
      padding: 0px 7px;
      list-style-type: none;
      a {
        color: #000;
        font-size: 12px;
        font-weight: 500;
        text-decoration: none;
      }
      &.active {
        a {
          color: #5578fa;
          font-weight: 700;
        }
      }
      &.disabled {
        a {
          color: #959595;
        }
      }
    }
  }
}
